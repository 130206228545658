import React from "react"
import { useTranslation } from "react-i18next"

import SchoolStationSection from "./SchoolStationSection"

import ArrowRightIconDark from "../../svg/arrow-right-dark.inline.svg"

import LetterIcon from "../../svg/letter-icon.inline.svg"

import PhoneIcon from "../../svg/phone-icon.inline.svg"

import InterestedIcon from "../../svg/interested-icon.inline.svg"

import { useSidemenu } from "../../components/SidemenuContext"

const SchoolItemContactsSection = props => {
  const {
    toggleRequestMeetingMenu,
    toggleApplyDocsMenu,
    toggleSendMessageMenu,
  } = useSidemenu()
  const school = props.school || {}
  let manager
  if (props.managers) {
    props.managers.forEach(m => {
      if (m.isMain === true) {
        manager = m
      }
    })
  }
  const { t } = useTranslation()
  return (
    <div
      className="white-block about-school-block school-contacts-page"
      style={{ paddingBottom: "220px" }}
    >
      <div className="about-school-content-wrapper container f-js">
        <div className="about-school-info row justify-content-center">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col key-data-title school-info-title">
              {t("school.contacts.contactsTitle")}
            </div>
          </div>

          {manager && (
            <div className="feedback-block row d-flex justify-content-center ">
              <div className="col-lg-1"></div>
              <div className="col-lg-5">
                <div className="feedback-item-wrapper advisor-block p-5 mt-5">
                  <div className="feedback-block-top-title">
                    {t("school.feedback.advisor")}
                  </div>
                  <div className="feedback-main-info">
                    <div
                      className="feedback-block-icon advisor-icon"
                      style={{
                        background: `url(${manager.resolvedImageUrl}) center no-repeat`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                    <div className="feedback-block-title">
                      {manager.firstname} {manager.lastname}
                    </div>
                    <div className="additional-info">{manager.position}</div>
                    <div className="interactivity-wrapper">
                      <div
                        className="f-ac curs-P"
                        onClick={toggleRequestMeetingMenu}
                      >
                        <PhoneIcon className="phone-icon" />
                        <div className="request-meeting">
                          {t("school.feedback.requestMeeting")}
                        </div>
                      </div>
                      <div
                        className="f-ac curs-P"
                        onClick={toggleSendMessageMenu}
                      >
                        <LetterIcon className="letter-icon" />
                        <div className="send-message">
                          {t("school.feedback.sendMessage")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="feedback-item-wrapper application-form-block p-5 mt-5">
                  <div className="feedback-block-top-title">
                    {t("school.feedback.applicationForm")}
                  </div>
                  {school.isSummer ? (
                    <div className="feedback-main-info">
                      <InterestedIcon className="feedback-block-icon interested-icon" />
                      <div className="feedback-block-title">
                        {t("school.feedback.interestedStudyingSummer")}
                      </div>
                      <div
                        className="interactivity-wrapper curs-P"
                        onClick={toggleApplyDocsMenu}
                      >
                        <div className="apply-doc-text">
                          {t("school.feedback.applyDocsSummer")}
                        </div>
                        <ArrowRightIconDark className="arrow_right" />
                      </div>
                    </div>
                  ) : (
                    <div className="feedback-main-info">
                      <InterestedIcon className="feedback-block-icon interested-icon" />
                      <div className="feedback-block-title">
                        {t("school.feedback.interestedStudying")}
                      </div>
                      <div
                        className="interactivity-wrapper curs-P"
                        onClick={toggleApplyDocsMenu}
                      >
                        <div className="apply-doc-text">
                          {t("school.feedback.applyDocs")}
                        </div>
                        <ArrowRightIconDark className="arrow_right" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          )}
          <SchoolStationSection school={props.school} contactsPage={true} />
        </div>
      </div>
    </div>
  )
}

export default SchoolItemContactsSection
