import React, { useEffect, useState } from "react"

import SchoolHeaderSection from "../../sections/schools/SchoolHeaderSection"
import SchoolItemContactsSection from "../../sections/schools/SchoolItemContactsSection"
import SchoolFooterSection from "../../sections/schools/SchoolFooterSection"

import SideNavRequestMeetingSection from "../../sections/SideNavRequestMeetingSection"
import SideNavSendMessageSection from "../../sections/SideNavSendMessageSection"
import SideNavApplyDocsSection from "../../sections/SideNavApplyDocsSection"
import Layout from "../../components/Layout"

const SchoolPageContacts = props => {
  const schoolsSelect = props.pageContext.schools
    ? props.pageContext.schools
    : props.pageContext.summerSchools

  return (
    <Layout
      wrapperClass={"school-item contacts-item"}
      pageTitle={`${props.pageContext.currentSchool.name} | Smart Admissions Service`}
      isSecondLayout={true}
    >
      <SchoolHeaderSection
        isMainSchoolTab={false}
        state8="active"
        currentSchoolScholarships={props.pageContext.currentSchoolScholarships}
        school={props.pageContext.currentSchool}
        schoolSectionHeader="shrinked"
        schoolSectionHeaderLogo="hide"
      />
      <SchoolItemContactsSection
        scholarships={props.pageContext.currentSchoolScholarships}
        school={props.pageContext.school}
        managers={props.pageContext.managers}
      />
      <SchoolFooterSection
        school={props.pageContext.currentSchool}
        schools={schoolsSelect}
      />

      <SideNavRequestMeetingSection school={props.pageContext.currentSchool} />
      <SideNavSendMessageSection school={props.pageContext.currentSchool} />
      <SideNavApplyDocsSection school={props.pageContext.currentSchool} />
    </Layout>
  )
}

export default SchoolPageContacts
